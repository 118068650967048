export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Editor',
    route: 'editor',
    icon: 'FileIcon',
  },
]
